.stepper_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.step-enter {
  opacity: 0;
  transform: translateX(-50px);
}

.step-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.step-exit {
  opacity: 0;
  transform: translateX(-50px);
}

.step-exit-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
