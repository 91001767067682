.rok__input__group .rok__input--defaultStyles {
  width: 60px;
  height: 60px;
  border: 1px solid;
  text-align: center;
  border-radius: 8px;
}

.rok__input__group .rok__defaultFill {
  border: 2px solid #0066ee;
}

.resendbutton__styles {
  background: #0066ee;
  color: #fff;
  padding: 10px 35px;
  border-radius: 10px;
  font-weight: 400;
  border: none;
  transition: all 0.5s ease;
}

.resendbutton__styles:disabled {
  background: #444;
  transition: all 0.5s ease;
  opacity: 0.5;
  cursor: not-allowed;
}

.rok__cta__container {
  padding-top: 40px;
}
